@import '~antd/dist/antd.less';

.ant-message {
    top: 48px !important;
}

.mobile-radio-table .ant-pro-card .ant-pro-card-body {
    padding: 0px;
}

@media screen and (max-width: 480px) {
    input {
        font-size: 16px !important;
    }
}
@primary-color: #1890ff;